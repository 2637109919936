<template>
    <div class="page-box">
        <div class="container">
            <div class="header">
                <img src="@/assets/icon/success.svg"/>
                <p>支付成功</p>
            </div>
            <div class="order-info">
                <div class="label-txt">订单信息</div>
                <div class="meta">
                    <div class="cell">订单号：{{orderInfo.payOrderId}}</div>
                    <div class="cell">商品名称：{{orderInfo.subject}}</div>
                    <div class="cell">支付金额：{{orderInfo.amount/100}}</div>
                </div>
            </div>
            <div class="tips">
                您已经成功付款，本次支付无实际产品或服务，只用于体验。您可点击下方发起退款按钮自行发起退款，如有问题请联系在线客服!
            </div>
            <div class="footer">
                <van-button class="btn" round type="default" @click="toDemo">继续体验</van-button>
                <van-button class="btn" round type="info" @click="handleRefundOrder">发起退款</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import {unifiedOrderQuery,refundOrder} from '@/api/api'
export default {
    name:"Order",
    data: function (){
        return {
            orderInfo:{}
        }
    },
    created(){
        let params = this.$route.query;
        this.loadOrder(params.mchOrderNo,params.payOrderId)
    },
    methods:{
        loadOrder(mchOrderNo,payOrderId){
            unifiedOrderQuery({mchOrderNo,payOrderId}).then(res=>{
                this.orderInfo = res;
            }).catch((err)=>{
                alert(err);
            })
        },
        handleRefundOrder(){
            refundOrder({mchRefundNo:'T'+new Date().getTime(),refundAmount:this.orderInfo.amount,refundReason:'测试退款',mchOrderNo:this.orderInfo.mchOrderNo,payOrderId:this.orderInfo.payOrderId}).then(()=>{
                this.$router.push({ path: "/demo"}).catch(() => {});
            }).catch((err)=>{
                alert(err);
            })
        },
        toDemo(){
          this.$router.push({ path: "/demo"}).catch(() => {});
        }
    }
}
</script>
<style scoped>
body,.page-box{
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
    min-height: 100vh;
}
.container{
    width: 80%;
    margin:0 auto;
    background-color: #fff;
}
.container .header{
    font-size: 12px;
    padding: 10px;
}
.container .order-info{
    width: 95%;
    height: 80px;
    border: 1px solid #00ae47;
    margin: 0 auto;
}
.container .order-info .label-txt{
    font-size: 9px;
    text-align: left;
    padding-left: 10px;
    height: 35px;
    line-height: 45px;
}
.container .order-info .meta{
    display: flex;
    height: 45px;
    line-height: 45px;
}
.container .order-info .meta .cell{
    flex: 1;
    font-size: 7px;
}
.container .tips{
    height: 45px;
    line-height: 45px;
    text-align: left;
    padding-left: 2.5%;
    font-size: 7px;
    color: #00ae47;
}
.container .footer .btn{
    margin: 10px;
}
</style>